import Storage from "@/util/Storage";
import moment from "moment";
import {getExpSchoolMonthIncome} from "@/Api/Platform/exp";
import {getShopSchoolMonthIncome} from "@/Api/Platform/shop";
import {filterToArray} from "@/views/PlatformAdmin/Util";


export function getExpMonthIncome(body :any= {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    body.startDate = moment(body.startDate).format("YYYY-MM");
    body.endDate = moment(body.endDate).format("YYYY-MM");
    return Promise.resolve(getExpSchoolMonthIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"无","dateTime","orderCount","amount","schoolFee","serviceFee","logistics","weixinFee",)
        }
    }))
}

export function getShopMonthIncome(body :any= {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    body.startDate = moment(body.startDate).format("YYYY-MM");
    body.endDate = moment(body.endDate).format("YYYY-MM");
    return Promise.resolve(getShopSchoolMonthIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"无","dateTime","orderCount","income","storeFee","schoolFee","serviceFee","weixinFee")
        }
    }))
}
