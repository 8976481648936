export const ExpTitleList = [
    {
        width: "20",
        value: "日期",
        after: true
    }, {
        width: "20",
        value: "订单量",
        after: true
    }, {
        width: "20",
        value: "总付款",
        after: true
    }, {
        width: "20",
        value: "校园收益",
        after: true
    }, {
        width: "20",
        value: "平台收益",
        after: true
    }, {
        width: "20",
        value: "配送工资",
        after: true
    },{
        width: "20",
        value: "微信抽成",
        after: false
    },
]

export const ShopTitleList = [
    {
        width: "20",
        value: "日期",
        after: true
    }, {
        width: "20",
        value: "订单量",
        after: true
    },{
        width:"20",
        value:"总付款",
        after:true
    },{
        width: "20",
        value: "店铺收入",
        after: true
    },{
        width: "20",
        value: "校园收益",
        after: true
    },{
        width: "20",
        value: "平台收益",
        after: true
    },{
        width: "20",
        value: "微信抽成",
        after: false
    },
]

export const TitleList = (state="exp")=>state=="exp"?ExpTitleList :ShopTitleList

export const testList = [
    [
        {
            value: "无",
        }, {
            value: "0",
        }, {
            value: "0",
        }, {
            value: "0",
        }, {
            value: "0",
        }, {
            value: "0",
        },{
            value: "0",
        },
    ]
]
