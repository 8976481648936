import { render, staticRenderFns } from "./MonthIncome.vue?vue&type=template&id=2d754719&"
import script from "./MonthIncome.vue?vue&type=script&lang=ts&"
export * from "./MonthIncome.vue?vue&type=script&lang=ts&"
import style0 from "./MonthIncome.vue?vue&type=style&index=0&id=2d754719&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports